import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {path: '/', component: () => import('./pages/MainPage.vue')},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise(function(resolve) {
            setTimeout(function() {
                if (savedPosition) {
                    // Use the saved position if available
                    resolve({ top: savedPosition.top });
                } else if (to.hash) {
                    // Scroll to the anchor if the route has a hash
                    resolve({ el: to.hash, behavior: 'smooth' });
                } else {
                    resolve({ top: 0 });
                }
            }, 600);
        });
    }
})

const app = createApp({});

app.use(router);

app.mount('#app');
